import { useState } from 'react';
import Login from './Login';
import Form from './Form';
import './App.css';  // Importar el archivo CSS para estilos

function App() {
  const [token, setToken] = useState(localStorage.getItem('token'));

  const handleSetToken = (newToken) => {
    setToken(newToken);
    localStorage.setItem('token', newToken);
  };

  // Función para cerrar sesión
  const handleLogout = () => {
    setToken(null);
    localStorage.removeItem('token');  // Elimina el token del localStorage
  };

  if (!token) {
    return <Login setToken={handleSetToken} />;
  }

  return (
    <div className='hero-container'>
      <div className='section1'>
        <h1>Hola</h1>
        <button onClick={handleLogout}>Logout</button>  {/* Botón de cerrar sesión */}
      </div>
      <Form token={token} />
    </div>
  );
}

export default App;
