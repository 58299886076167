import { useState } from 'react';
import axios from 'axios';
import './Login.css';  // Asegúrate de tener el archivo CSS importado

function Login({ setToken }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);  // Indicador de carga
  const [error, setError] = useState('');  // Mensaje de error

  const handleLogin = async () => {
    setLoading(true);
    setError('');  // Reiniciar el error al intentar login
    try {
      const response = await axios.post('/api/login', {
        username,
        password
      });
  
      // Verifica si se recibió una respuesta con el token
      if (response.data.token) {
        setToken(response.data.token);
      } else {
        setError('No se recibió un token en la respuesta.');
      }
    } catch (error) {
      console.error('Login error:', error);
  
      // Si el servidor respondió con un error
      if (error.response && error.response.data) {
        console.log(error.response.data);
      }
  
      setError('Error en el inicio de sesión. Verifica tus credenciales.');
    } finally {
      setLoading(false);  // Terminar la carga
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>Login</h2>
        {error && <p className="error">{error}</p>}  {/* Mensaje de error */}
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="input-field"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="input-field"
        />
        <button onClick={handleLogin} disabled={loading} className="login-button">
          {loading ? 'Logging in...' : 'Login'}  {/* Indicador de carga */}
        </button>
      </div>
    </div>
  );
}

export default Login;
