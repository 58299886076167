import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import './Form.css';  // Importar el archivo CSS para estilos

function Form({ token }) {
  const [fields, setFields] = useState({ field1: '', field2: '', field3: '', field4: '' });
  const [myForms, setMyForms] = useState([]);
  const [allForms, setAllForms] = useState([]);
  const [loading, setLoading] = useState(false);  // Indicador de carga
  const [error, setError] = useState('');  // Mensaje de error
  const [success, setSuccess] = useState('');  // Mensaje de éxito

  const fetchMyForms = useCallback(async () => {
    try {
      const response = await axios.get('/api/myforms', {
        headers: { 'x-access-token': token }
      });
      setMyForms(response.data);
    } catch (error) {
      console.error('Error fetching forms:', error);
    }
  }, [token]);

  const fetchAllForms = useCallback(async () => {
    try {
      const response = await axios.get('/api/allforms', {
        headers: { 'x-access-token': token }
      });
      setAllForms(response.data);
    } catch (error) {
      console.error('Error fetching all forms:', error);
    }
  }, [token]);

  useEffect(() => {
    fetchMyForms();
    fetchAllForms();
  }, [fetchMyForms, fetchAllForms]);

  const handleSubmit = async () => {
    setLoading(true);
    setError('');
    setSuccess('');
    try {
      await axios.post('/api/forms', {
        field1: fields.field1,
        field2: fields.field2,
        field3: fields.field3,
        field4: fields.field4,
        token
      });
      setSuccess('Form submitted successfully!');
      setFields({ field1: '', field2: '', field3: '', field4: '' });  // Limpiar el formulario
      fetchMyForms();  // Recargar los formularios
      fetchAllForms(); // Recargar los formularios de admin
    } catch (error) {
      console.error('Submit error:', error);
      setError('Error submitting form.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="form-container">
      <h2>Formulario</h2>
      {error && <p className="error-message">{error}</p>}  {/* Mensaje de error */}
      {success && <p className="success-message">{success}</p>}  {/* Mensaje de éxito */}
      
      <input
        type="text"
        placeholder="Field 1"
        value={fields.field1}
        onChange={(e) => setFields({ ...fields, field1: e.target.value })}
      />
      <input
        type="text"
        placeholder="Field 2"
        value={fields.field2}
        onChange={(e) => setFields({ ...fields, field2: e.target.value })}
      />
      <input
        type="text"
        placeholder="Field 3"
        value={fields.field3}
        onChange={(e) => setFields({ ...fields, field3: e.target.value })}
      />
      <input
        type="text"
        placeholder="Field 4"
        value={fields.field4}
        onChange={(e) => setFields({ ...fields, field4: e.target.value })}
      />
      <button className="submit-btn" onClick={handleSubmit} disabled={loading}>
        {loading ? 'Submitting...' : 'Submit'}
      </button>

      <div className="form-section">
        <h2>Mis formularios</h2>
        {myForms.length > 0 ? (
          <ul className="form-list">
            {myForms.map(form => (
              <li key={form.id}>
                {form.field1}, {form.field2}, {form.field3}, {form.field4}
              </li>
            ))}
          </ul>
        ) : (
          <p>No hay formularios para mostrar</p>
        )}
      </div>

      <div className="form-section">
        <h2>Todos los formularios (Solo admin)</h2>
        {allForms.length > 0 ? (
          <ul className="form-list">
            {allForms.map(form => (
              <li key={form.id}>
                <strong>Usuario:</strong> {form.username} <br />
                {form.field1}, {form.field2}, {form.field3}, {form.field4}
              </li>
            ))}
          </ul>
        ) : (
          <p>No hay formularios para mostrar</p>
        )}
      </div>

    </div>
  );
}

export default Form;
